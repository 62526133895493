import React, { Component } from "react";

export default class DataScienceImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg width="739" height="577" viewBox="0 0 739 577" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_4_107)">
<path d="M614.379 248.524C614.379 248.524 597.009 258.186 602.009 269.186C607.009 280.186 678.009 271.186 678.009 271.186C678.009 271.186 673.009 256.186 664.009 253.186C655.009 250.186 614.379 248.524 614.379 248.524Z" fill="#0E6BA8"/>
<path d="M607.607 251.1C611.099 248.831 614.979 247.226 619.054 246.367C632.644 243.073 646.873 243.517 660.231 247.651L659.986 247.452C665.678 250.456 670.674 254.622 674.652 259.68L607.607 251.1Z" fill="#CCCCCC"/>
<path d="M684.529 298.405C684.289 301.995 684.049 305.582 683.809 309.165C683.48 316.671 682.661 324.147 681.359 331.545C680.139 337.735 678.199 343.875 674.729 349.145C671.259 354.405 666.109 358.755 660.009 360.355C656.263 361.253 652.369 361.338 648.589 360.605C641.459 356.555 636.629 361.735 643.749 367.005C645.364 368.2 647.043 369.305 648.779 370.315L652.009 420.185L592.009 407.185L603.719 340.845C601.925 339.216 600.285 337.425 598.819 335.495C592.109 326.585 589.799 314.975 590.239 303.835C590.689 292.685 593.659 281.815 596.609 271.065C597.361 268.149 598.269 265.275 599.329 262.455C600.639 262.995 601.969 263.485 603.319 263.895C611.949 266.515 621.119 266.725 630.139 266.715C646.189 266.705 662.219 266.079 678.229 264.835C681.908 270.892 684.142 277.715 684.759 284.775C685.017 289.318 684.94 293.874 684.529 298.405V298.405Z" fill="#CCCCCC"/>
<path d="M427.91 433.78L520.28 441.98L521.27 442.07V47.93C520.94 47.7 520.61 47.47 520.28 47.24C501.029 33.7747 480.205 22.7127 458.27 14.3L453.55 79.4L453.12 85.36L448.45 149.92L447.01 169.78L428.08 431.48L427.91 433.78Z" fill="#E6E6E6"/>
<path d="M199.48 40.59C198.82 41.01 198.16 41.43 197.5 41.87V442.07L199.48 441.89L290.85 433.78L290.68 431.48L271.75 169.78L270.31 149.92L265.64 85.36L265.21 79.4L260.26 11.13C238.913 18.5294 218.513 28.4175 199.48 40.59V40.59Z" fill="#E6E6E6"/>
<path d="M197.5 41.87C196.17 42.73 194.84 43.61 193.53 44.5V447.53C193.53 457.6 199.5 466.04 207.48 468.19C208.784 468.543 210.129 468.721 211.48 468.72H508.28C509.628 468.722 510.97 468.544 512.27 468.19C515.236 467.367 517.925 465.757 520.05 463.53C524.103 459.198 526.319 453.462 526.23 447.53V51.51C524.59 50.3 522.94 49.1 521.27 47.93C520.94 47.7 520.61 47.47 520.28 47.24V447.53C520.282 448.827 520.128 450.12 519.82 451.38C518.41 457.33 513.76 461.69 508.28 461.69H211.48C204.86 461.69 199.48 455.34 199.48 447.53V40.59C198.82 41.01 198.16 41.43 197.5 41.87Z" fill="#E6E6E6"/>
<path d="M523.255 79.404H196.503V85.363H523.255V79.404Z" fill="#E6E6E6"/>
<path d="M423.18 3.39001C421.2 2.90001 419.22 2.44001 417.22 2.01001V163.82H423.18V3.39001Z" fill="#E6E6E6"/>
<path d="M302.54 1.54932e-05C300.55 0.370015 298.56 0.750004 296.58 1.16V163.82H302.54V1.54932e-05Z" fill="#E6E6E6"/>
<path d="M132.44 458.29C132.439 461.71 133.094 465.098 134.37 468.27C134.86 469.5 135.445 470.691 136.12 471.83C136.991 473.316 138.003 474.716 139.14 476.01C141.652 478.873 144.747 481.167 148.216 482.738C151.686 484.309 155.451 485.121 159.26 485.12H550.57C557.683 485.12 564.505 482.294 569.535 477.265C574.564 472.235 577.39 465.413 577.39 458.3C577.39 451.187 574.564 444.365 569.535 439.335C564.505 434.306 557.683 431.48 550.57 431.48H159.26C152.151 431.487 145.335 434.314 140.307 439.34C135.279 444.366 132.45 451.181 132.44 458.29Z" fill="#E6E6E6"/>
<path d="M196.503 169.782L521.268 169.782V149.919L196.503 149.919V169.782Z" fill="#E6E6E6"/>
<path d="M372.79 154.885H344.981C344.981 153.45 345.264 152.029 345.813 150.704C346.362 149.379 347.167 148.174 348.181 147.16C349.195 146.145 350.4 145.341 351.725 144.792C353.051 144.242 354.471 143.96 355.906 143.96H361.865C364.762 143.96 367.541 145.111 369.59 147.16C371.639 149.209 372.79 151.987 372.79 154.885V154.885H372.79Z" fill="#E6E6E6"/>
<path d="M-3.36621e-05 473.61C8.68722 483.903 18.0835 493.577 28.12 502.56H710.7C720.736 493.577 730.133 483.903 738.82 473.61H-3.36621e-05Z" fill="#3F3D56"/>
<path d="M185.16 376.8V479.36H257.01V376.8C257.011 376.062 256.776 375.344 256.338 374.75C255.901 374.156 255.285 373.718 254.58 373.5C254.248 373.39 253.9 373.336 253.55 373.34H188.63C188.175 373.339 187.724 373.428 187.303 373.601C186.883 373.775 186.5 374.029 186.178 374.351C185.856 374.672 185.6 375.054 185.425 375.474C185.251 375.894 185.16 376.345 185.16 376.8ZM213.2 412.04C213.219 410.018 214.035 408.085 215.471 406.662C216.908 405.239 218.848 404.441 220.87 404.441C222.892 404.441 224.832 405.239 226.268 406.662C227.705 408.085 228.521 410.018 228.54 412.04V423.94C228.53 425.967 227.717 427.908 226.28 429.338C224.842 430.768 222.897 431.571 220.87 431.571C218.843 431.571 216.898 430.768 215.46 429.338C214.023 427.908 213.21 425.967 213.2 423.94V412.04Z" fill="#2F2E41"/>
<path d="M184.94 476.08V485.97C184.942 486.482 185.145 486.973 185.506 487.336C185.868 487.7 186.358 487.906 186.87 487.91H255.3C255.813 487.907 256.304 487.702 256.665 487.338C257.027 486.975 257.23 486.483 257.23 485.97V476.08H184.94Z" fill="#3F3D56"/>
<path d="M72.13 222.28V409.54C72.1319 411.926 73.0807 414.214 74.7681 415.902C76.4555 417.589 78.7436 418.538 81.13 418.54H358.63C361.016 418.538 363.304 417.589 364.992 415.902C366.679 414.214 367.628 411.926 367.63 409.54V222.28C367.624 219.896 366.674 217.611 364.987 215.926C363.3 214.241 361.014 213.293 358.63 213.29H81.13C78.7457 213.293 76.4598 214.241 74.7729 215.926C73.086 217.611 72.1357 219.896 72.13 222.28V222.28Z" fill="#3F3D56"/>
<path d="M78.64 224.61V407.23C78.6422 408.505 79.1496 409.727 80.0512 410.629C80.9528 411.53 82.175 412.038 83.45 412.04H356.32C357.595 412.038 358.817 411.53 359.719 410.629C360.62 409.727 361.128 408.505 361.13 407.23V224.61C361.13 223.333 360.623 222.109 359.722 221.205C358.82 220.302 357.597 219.793 356.32 219.79H83.45C82.1734 219.793 80.9501 220.302 80.0483 221.205C79.1466 222.109 78.6401 223.333 78.64 224.61V224.61Z" fill="#0E6BA8"/>
<path d="M134.28 490.24C134.558 490.588 134.911 490.869 135.313 491.061C135.714 491.254 136.155 491.352 136.6 491.35H298.94C299.384 491.349 299.822 491.25 300.223 491.059C300.624 490.867 300.977 490.589 301.256 490.245C301.536 489.9 301.735 489.497 301.84 489.065C301.944 488.634 301.951 488.185 301.86 487.75L299.34 475.78C299.236 475.292 299.011 474.838 298.686 474.459C298.361 474.08 297.946 473.788 297.48 473.61C297.142 473.477 296.783 473.41 296.42 473.41H139.11C138.747 473.41 138.388 473.477 138.05 473.61C137.584 473.788 137.169 474.08 136.844 474.459C136.519 474.838 136.294 475.292 136.19 475.78L133.67 487.75C133.58 488.184 133.589 488.633 133.694 489.064C133.8 489.495 134 489.896 134.28 490.24Z" fill="#2F2E41"/>
<path d="M290.755 478.566H294.779C295.048 478.566 295.267 478.347 295.267 478.078V476.054C295.267 475.784 295.048 475.566 294.779 475.566H290.755C290.486 475.566 290.267 475.784 290.267 476.054V478.078C290.267 478.347 290.486 478.566 290.755 478.566Z" fill="#3F3D56"/>
<path d="M280.755 478.566H284.779C285.048 478.566 285.267 478.347 285.267 478.078V476.054C285.267 475.784 285.048 475.566 284.779 475.566H280.755C280.486 475.566 280.267 475.784 280.267 476.054V478.078C280.267 478.347 280.486 478.566 280.755 478.566Z" fill="#3F3D56"/>
<path d="M270.755 478.566H274.779C275.048 478.566 275.267 478.347 275.267 478.078V476.054C275.267 475.784 275.048 475.566 274.779 475.566H270.755C270.486 475.566 270.267 475.784 270.267 476.054V478.078C270.267 478.347 270.486 478.566 270.755 478.566Z" fill="#3F3D56"/>
<path d="M260.755 478.566H264.779C265.048 478.566 265.267 478.347 265.267 478.078V476.054C265.267 475.784 265.048 475.566 264.779 475.566H260.755C260.486 475.566 260.267 475.784 260.267 476.054V478.078C260.267 478.347 260.486 478.566 260.755 478.566Z" fill="#3F3D56"/>
<path d="M250.755 478.566H254.779C255.048 478.566 255.267 478.347 255.267 478.078V476.054C255.267 475.784 255.048 475.566 254.779 475.566H250.755C250.486 475.566 250.267 475.784 250.267 476.054V478.078C250.267 478.347 250.486 478.566 250.755 478.566Z" fill="#3F3D56"/>
<path d="M240.755 478.566H244.779C245.048 478.566 245.267 478.347 245.267 478.078V476.054C245.267 475.784 245.048 475.566 244.779 475.566H240.755C240.486 475.566 240.267 475.784 240.267 476.054V478.078C240.267 478.347 240.486 478.566 240.755 478.566Z" fill="#3F3D56"/>
<path d="M230.755 478.566H234.779C235.048 478.566 235.267 478.347 235.267 478.078V476.054C235.267 475.784 235.048 475.566 234.779 475.566H230.755C230.486 475.566 230.267 475.784 230.267 476.054V478.078C230.267 478.347 230.486 478.566 230.755 478.566Z" fill="#3F3D56"/>
<path d="M220.755 478.566H224.779C225.048 478.566 225.267 478.347 225.267 478.078V476.054C225.267 475.784 225.048 475.566 224.779 475.566H220.755C220.486 475.566 220.267 475.784 220.267 476.054V478.078C220.267 478.347 220.486 478.566 220.755 478.566Z" fill="#3F3D56"/>
<path d="M210.755 478.566H214.779C215.048 478.566 215.267 478.347 215.267 478.078V476.054C215.267 475.784 215.048 475.566 214.779 475.566H210.755C210.486 475.566 210.267 475.784 210.267 476.054V478.078C210.267 478.347 210.486 478.566 210.755 478.566Z" fill="#3F3D56"/>
<path d="M200.755 478.566H204.779C205.048 478.566 205.267 478.347 205.267 478.078V476.054C205.267 475.784 205.048 475.566 204.779 475.566H200.755C200.486 475.566 200.267 475.784 200.267 476.054V478.078C200.267 478.347 200.486 478.566 200.755 478.566Z" fill="#3F3D56"/>
<path d="M190.755 478.566H194.779C195.048 478.566 195.267 478.347 195.267 478.078V476.054C195.267 475.784 195.048 475.566 194.779 475.566H190.755C190.486 475.566 190.267 475.784 190.267 476.054V478.078C190.267 478.347 190.486 478.566 190.755 478.566Z" fill="#3F3D56"/>
<path d="M180.755 478.566H184.779C185.048 478.566 185.267 478.347 185.267 478.078V476.054C185.267 475.784 185.048 475.566 184.779 475.566H180.755C180.486 475.566 180.267 475.784 180.267 476.054V478.078C180.267 478.347 180.486 478.566 180.755 478.566Z" fill="#3F3D56"/>
<path d="M170.755 478.566H174.779C175.048 478.566 175.267 478.347 175.267 478.078V476.054C175.267 475.784 175.048 475.566 174.779 475.566H170.755C170.486 475.566 170.267 475.784 170.267 476.054V478.078C170.267 478.347 170.486 478.566 170.755 478.566Z" fill="#3F3D56"/>
<path d="M160.755 478.566H164.779C165.048 478.566 165.267 478.347 165.267 478.078V476.054C165.267 475.784 165.048 475.566 164.779 475.566H160.755C160.486 475.566 160.267 475.784 160.267 476.054V478.078C160.267 478.347 160.486 478.566 160.755 478.566Z" fill="#3F3D56"/>
<path d="M150.755 478.566H154.779C155.048 478.566 155.267 478.347 155.267 478.078V476.054C155.267 475.784 155.048 475.566 154.779 475.566H150.755C150.486 475.566 150.267 475.784 150.267 476.054V478.078C150.267 478.347 150.486 478.566 150.755 478.566Z" fill="#3F3D56"/>
<path d="M140.755 478.566H144.779C145.048 478.566 145.267 478.347 145.267 478.078V476.054C145.267 475.784 145.048 475.566 144.779 475.566H140.755C140.486 475.566 140.267 475.784 140.267 476.054V478.078C140.267 478.347 140.486 478.566 140.755 478.566Z" fill="#3F3D56"/>
<path d="M290.873 483.566H294.897C295.167 483.566 295.385 483.347 295.385 483.078V481.054C295.385 480.784 295.167 480.566 294.897 480.566H290.873C290.604 480.566 290.385 480.784 290.385 481.054V483.078C290.385 483.347 290.604 483.566 290.873 483.566Z" fill="#3F3D56"/>
<path d="M280.873 483.566H284.897C285.167 483.566 285.385 483.347 285.385 483.078V481.054C285.385 480.784 285.167 480.566 284.897 480.566H280.873C280.604 480.566 280.385 480.784 280.385 481.054V483.078C280.385 483.347 280.604 483.566 280.873 483.566Z" fill="#3F3D56"/>
<path d="M270.873 483.566H274.897C275.167 483.566 275.385 483.347 275.385 483.078V481.054C275.385 480.784 275.167 480.566 274.897 480.566H270.873C270.604 480.566 270.385 480.784 270.385 481.054V483.078C270.385 483.347 270.604 483.566 270.873 483.566Z" fill="#3F3D56"/>
<path d="M260.873 483.566H264.897C265.167 483.566 265.385 483.347 265.385 483.078V481.054C265.385 480.784 265.167 480.566 264.897 480.566H260.873C260.604 480.566 260.385 480.784 260.385 481.054V483.078C260.385 483.347 260.604 483.566 260.873 483.566Z" fill="#3F3D56"/>
<path d="M250.873 483.566H254.897C255.167 483.566 255.385 483.347 255.385 483.078V481.054C255.385 480.784 255.167 480.566 254.897 480.566H250.873C250.604 480.566 250.385 480.784 250.385 481.054V483.078C250.385 483.347 250.604 483.566 250.873 483.566Z" fill="#3F3D56"/>
<path d="M240.873 483.566H244.897C245.167 483.566 245.385 483.347 245.385 483.078V481.054C245.385 480.784 245.167 480.566 244.897 480.566H240.873C240.604 480.566 240.385 480.784 240.385 481.054V483.078C240.385 483.347 240.604 483.566 240.873 483.566Z" fill="#3F3D56"/>
<path d="M230.873 483.566H234.897C235.167 483.566 235.385 483.347 235.385 483.078V481.054C235.385 480.784 235.167 480.566 234.897 480.566H230.873C230.604 480.566 230.385 480.784 230.385 481.054V483.078C230.385 483.347 230.604 483.566 230.873 483.566Z" fill="#3F3D56"/>
<path d="M220.873 483.566H224.897C225.167 483.566 225.385 483.347 225.385 483.078V481.054C225.385 480.784 225.167 480.566 224.897 480.566H220.873C220.604 480.566 220.385 480.784 220.385 481.054V483.078C220.385 483.347 220.604 483.566 220.873 483.566Z" fill="#3F3D56"/>
<path d="M210.873 483.566H214.897C215.167 483.566 215.385 483.347 215.385 483.078V481.054C215.385 480.784 215.167 480.566 214.897 480.566H210.873C210.604 480.566 210.385 480.784 210.385 481.054V483.078C210.385 483.347 210.604 483.566 210.873 483.566Z" fill="#3F3D56"/>
<path d="M200.873 483.566H204.897C205.167 483.566 205.385 483.347 205.385 483.078V481.054C205.385 480.784 205.167 480.566 204.897 480.566H200.873C200.604 480.566 200.385 480.784 200.385 481.054V483.078C200.385 483.347 200.604 483.566 200.873 483.566Z" fill="#3F3D56"/>
<path d="M190.873 483.566H194.897C195.167 483.566 195.385 483.347 195.385 483.078V481.054C195.385 480.784 195.167 480.566 194.897 480.566H190.873C190.604 480.566 190.385 480.784 190.385 481.054V483.078C190.385 483.347 190.604 483.566 190.873 483.566Z" fill="#3F3D56"/>
<path d="M180.873 483.566H184.897C185.167 483.566 185.385 483.347 185.385 483.078V481.054C185.385 480.784 185.167 480.566 184.897 480.566H180.873C180.604 480.566 180.385 480.784 180.385 481.054V483.078C180.385 483.347 180.604 483.566 180.873 483.566Z" fill="#3F3D56"/>
<path d="M170.873 483.566H174.897C175.167 483.566 175.385 483.347 175.385 483.078V481.054C175.385 480.784 175.167 480.566 174.897 480.566H170.873C170.604 480.566 170.385 480.784 170.385 481.054V483.078C170.385 483.347 170.604 483.566 170.873 483.566Z" fill="#3F3D56"/>
<path d="M160.873 483.566H164.897C165.167 483.566 165.385 483.347 165.385 483.078V481.054C165.385 480.784 165.167 480.566 164.897 480.566H160.873C160.604 480.566 160.385 480.784 160.385 481.054V483.078C160.385 483.347 160.604 483.566 160.873 483.566Z" fill="#3F3D56"/>
<path d="M150.873 483.566H154.897C155.167 483.566 155.385 483.347 155.385 483.078V481.054C155.385 480.784 155.167 480.566 154.897 480.566H150.873C150.604 480.566 150.385 480.784 150.385 481.054V483.078C150.385 483.347 150.604 483.566 150.873 483.566Z" fill="#3F3D56"/>
<path d="M140.873 483.566H144.897C145.167 483.566 145.385 483.347 145.385 483.078V481.054C145.385 480.784 145.167 480.566 144.897 480.566H140.873C140.604 480.566 140.385 480.784 140.385 481.054V483.078C140.385 483.347 140.604 483.566 140.873 483.566Z" fill="#3F3D56"/>
<path d="M207.873 489.566H246.897C247.167 489.566 247.385 489.347 247.385 489.078V487.054C247.385 486.784 247.167 486.566 246.897 486.566H207.873C207.604 486.566 207.385 486.784 207.385 487.054V489.078C207.385 489.347 207.604 489.566 207.873 489.566Z" fill="#3F3D56"/>
<path d="M97.1618 244.947H352.485C352.751 244.941 353.003 244.831 353.189 244.642C353.374 244.452 353.479 244.197 353.479 243.931C353.479 243.666 353.374 243.411 353.189 243.221C353.003 243.031 352.751 242.922 352.485 242.916H97.1618C96.8964 242.922 96.6438 243.031 96.4582 243.221C96.2725 243.411 96.1685 243.666 96.1685 243.931C96.1685 244.197 96.2725 244.452 96.4582 244.642C96.6438 244.831 96.8964 244.941 97.1618 244.947V244.947Z" fill="#3F3D56"/>
<path d="M335.105 237.732C338.391 237.732 341.055 235.008 341.055 231.649C341.055 228.289 338.391 225.566 335.105 225.566C331.819 225.566 329.155 228.289 329.155 231.649C329.155 235.008 331.819 237.732 335.105 237.732Z" fill="#3F3D56"/>
<path d="M314.549 237.732C317.835 237.732 320.499 235.008 320.499 231.649C320.499 228.289 317.835 225.566 314.549 225.566C311.263 225.566 308.599 228.289 308.599 231.649C308.599 235.008 311.263 237.732 314.549 237.732Z" fill="#3F3D56"/>
<path d="M293.993 237.732C297.279 237.732 299.943 235.008 299.943 231.649C299.943 228.289 297.279 225.566 293.993 225.566C290.707 225.566 288.043 228.289 288.043 231.649C288.043 235.008 290.707 237.732 293.993 237.732Z" fill="#3F3D56"/>
<path d="M108.45 228.18C108.449 228.324 108.477 228.466 108.531 228.599C108.586 228.733 108.666 228.854 108.767 228.956C108.868 229.058 108.989 229.139 109.121 229.195C109.254 229.25 109.396 229.279 109.54 229.28H124.14C124.429 229.273 124.703 229.154 124.905 228.947C125.107 228.741 125.22 228.464 125.22 228.175C125.22 227.886 125.107 227.609 124.905 227.403C124.703 227.196 124.429 227.077 124.14 227.07H109.54C109.249 227.074 108.97 227.192 108.766 227.4C108.562 227.608 108.448 227.889 108.45 228.18V228.18Z" fill="#3F3D56"/>
<path d="M108.45 232.33C108.449 232.474 108.477 232.616 108.531 232.749C108.586 232.883 108.666 233.004 108.767 233.106C108.868 233.208 108.989 233.289 109.121 233.345C109.254 233.4 109.396 233.429 109.54 233.43H124.14C124.429 233.424 124.704 233.304 124.906 233.098C125.108 232.891 125.221 232.614 125.221 232.325C125.221 232.036 125.108 231.759 124.906 231.552C124.704 231.346 124.429 231.226 124.14 231.22H109.54C109.249 231.224 108.97 231.342 108.766 231.55C108.562 231.758 108.448 232.039 108.45 232.33V232.33Z" fill="#3F3D56"/>
<path d="M108.45 236.47C108.448 236.761 108.562 237.042 108.766 237.25C108.97 237.458 109.249 237.576 109.54 237.58H124.14C124.429 237.573 124.703 237.454 124.905 237.247C125.107 237.041 125.22 236.764 125.22 236.475C125.22 236.186 125.107 235.909 124.905 235.703C124.703 235.496 124.429 235.377 124.14 235.37H109.54C109.396 235.371 109.254 235.4 109.121 235.455C108.989 235.511 108.868 235.592 108.767 235.694C108.666 235.796 108.586 235.917 108.531 236.051C108.477 236.184 108.449 236.326 108.45 236.47V236.47Z" fill="#3F3D56"/>
<path d="M112.962 268.543H107.716C106.125 268.543 104.836 269.833 104.836 271.423C104.836 273.014 106.125 274.303 107.716 274.303H112.962C114.553 274.303 115.842 273.014 115.842 271.423C115.842 269.833 114.553 268.543 112.962 268.543Z" fill="#E6E6E6"/>
<path d="M172.962 268.543H167.716C166.125 268.543 164.836 269.833 164.836 271.423C164.836 273.014 166.125 274.303 167.716 274.303H172.962C174.553 274.303 175.842 273.014 175.842 271.423C175.842 269.833 174.553 268.543 172.962 268.543Z" fill="#E6E6E6"/>
<path d="M189.962 268.543H184.716C183.125 268.543 181.836 269.833 181.836 271.423C181.836 273.014 183.125 274.303 184.716 274.303H189.962C191.553 274.303 192.842 273.014 192.842 271.423C192.842 269.833 191.553 268.543 189.962 268.543Z" fill="#E6E6E6"/>
<path d="M156.642 268.063H124.036C122.446 268.063 121.156 269.353 121.156 270.943C121.156 272.534 122.446 273.823 124.036 273.823H156.642C158.232 273.823 159.522 272.534 159.522 270.943C159.522 269.353 158.232 268.063 156.642 268.063Z" fill="#E6E6E6"/>
<path d="M236.642 268.063H204.036C202.446 268.063 201.156 269.353 201.156 270.943C201.156 272.534 202.446 273.823 204.036 273.823H236.642C238.232 273.823 239.522 272.534 239.522 270.943C239.522 269.353 238.232 268.063 236.642 268.063Z" fill="#E6E6E6"/>
<path d="M128.962 281.543H123.716C122.125 281.543 120.836 282.833 120.836 284.423C120.836 286.014 122.125 287.303 123.716 287.303H128.962C130.553 287.303 131.842 286.014 131.842 284.423C131.842 282.833 130.553 281.543 128.962 281.543Z" fill="#E6E6E6"/>
<path d="M188.962 281.543H183.716C182.125 281.543 180.836 282.833 180.836 284.423C180.836 286.014 182.125 287.303 183.716 287.303H188.962C190.553 287.303 191.842 286.014 191.842 284.423C191.842 282.833 190.553 281.543 188.962 281.543Z" fill="#E6E6E6"/>
<path d="M205.962 281.543H200.716C199.125 281.543 197.836 282.833 197.836 284.423C197.836 286.014 199.125 287.303 200.716 287.303H205.962C207.553 287.303 208.842 286.014 208.842 284.423C208.842 282.833 207.553 281.543 205.962 281.543Z" fill="#E6E6E6"/>
<path d="M172.642 281.063H140.036C138.446 281.063 137.156 282.353 137.156 283.943C137.156 285.534 138.446 286.823 140.036 286.823H172.642C174.232 286.823 175.522 285.534 175.522 283.943C175.522 282.353 174.232 281.063 172.642 281.063Z" fill="#E6E6E6"/>
<path d="M252.642 281.063H220.036C218.446 281.063 217.156 282.353 217.156 283.943C217.156 285.534 218.446 286.823 220.036 286.823H252.642C254.232 286.823 255.522 285.534 255.522 283.943C255.522 282.353 254.232 281.063 252.642 281.063Z" fill="#E6E6E6"/>
<path d="M144.962 294.543H139.716C138.125 294.543 136.836 295.833 136.836 297.423C136.836 299.014 138.125 300.303 139.716 300.303H144.962C146.553 300.303 147.842 299.014 147.842 297.423C147.842 295.833 146.553 294.543 144.962 294.543Z" fill="#E6E6E6"/>
<path d="M204.962 294.543H199.716C198.125 294.543 196.836 295.833 196.836 297.423C196.836 299.014 198.125 300.303 199.716 300.303H204.962C206.553 300.303 207.842 299.014 207.842 297.423C207.842 295.833 206.553 294.543 204.962 294.543Z" fill="#E6E6E6"/>
<path d="M221.962 294.543H216.716C215.125 294.543 213.836 295.833 213.836 297.423C213.836 299.014 215.125 300.303 216.716 300.303H221.962C223.553 300.303 224.842 299.014 224.842 297.423C224.842 295.833 223.553 294.543 221.962 294.543Z" fill="#E6E6E6"/>
<path d="M188.642 294.063H156.036C154.446 294.063 153.156 295.353 153.156 296.943C153.156 298.534 154.446 299.823 156.036 299.823H188.642C190.232 299.823 191.522 298.534 191.522 296.943C191.522 295.353 190.232 294.063 188.642 294.063Z" fill="#E6E6E6"/>
<path d="M268.642 294.063H236.036C234.446 294.063 233.156 295.353 233.156 296.943C233.156 298.534 234.446 299.823 236.036 299.823H268.642C270.232 299.823 271.522 298.534 271.522 296.943C271.522 295.353 270.232 294.063 268.642 294.063Z" fill="#E6E6E6"/>
<path d="M160.962 307.543H155.716C154.125 307.543 152.836 308.833 152.836 310.423C152.836 312.014 154.125 313.303 155.716 313.303H160.962C162.553 313.303 163.842 312.014 163.842 310.423C163.842 308.833 162.553 307.543 160.962 307.543Z" fill="#E6E6E6"/>
<path d="M220.962 307.543H215.716C214.125 307.543 212.836 308.833 212.836 310.423C212.836 312.014 214.125 313.303 215.716 313.303H220.962C222.553 313.303 223.842 312.014 223.842 310.423C223.842 308.833 222.553 307.543 220.962 307.543Z" fill="#E6E6E6"/>
<path d="M237.962 307.543H232.716C231.125 307.543 229.836 308.833 229.836 310.423C229.836 312.014 231.125 313.303 232.716 313.303H237.962C239.553 313.303 240.842 312.014 240.842 310.423C240.842 308.833 239.553 307.543 237.962 307.543Z" fill="#E6E6E6"/>
<path d="M204.642 307.063H172.036C170.446 307.063 169.156 308.353 169.156 309.943C169.156 311.534 170.446 312.823 172.036 312.823H204.642C206.232 312.823 207.522 311.534 207.522 309.943C207.522 308.353 206.232 307.063 204.642 307.063Z" fill="#E6E6E6"/>
<path d="M172.962 359.237H167.716C166.125 359.237 164.836 360.526 164.836 362.117C164.836 363.707 166.125 364.997 167.716 364.997H172.962C174.553 364.997 175.842 363.707 175.842 362.117C175.842 360.526 174.553 359.237 172.962 359.237Z" fill="#E6E6E6"/>
<path d="M189.962 359.237H184.716C183.125 359.237 181.836 360.526 181.836 362.117C181.836 363.707 183.125 364.997 184.716 364.997H189.962C191.553 364.997 192.842 363.707 192.842 362.117C192.842 360.526 191.553 359.237 189.962 359.237Z" fill="#E6E6E6"/>
<path d="M236.642 359.717H204.036C202.446 359.717 201.156 361.006 201.156 362.597C201.156 364.188 202.446 365.477 204.036 365.477H236.642C238.232 365.477 239.522 364.188 239.522 362.597C239.522 361.006 238.232 359.717 236.642 359.717Z" fill="#E6E6E6"/>
<path d="M112.962 359.237H107.716C106.125 359.237 104.836 360.526 104.836 362.117C104.836 363.707 106.125 364.997 107.716 364.997H112.962C114.553 364.997 115.842 363.707 115.842 362.117C115.842 360.526 114.553 359.237 112.962 359.237Z" fill="#E6E6E6"/>
<path d="M156.642 359.717H124.036C122.446 359.717 121.156 361.006 121.156 362.597C121.156 364.188 122.446 365.477 124.036 365.477H156.642C158.232 365.477 159.522 364.188 159.522 362.597C159.522 361.006 158.232 359.717 156.642 359.717Z" fill="#E6E6E6"/>
<path d="M128.962 346.237H123.716C122.125 346.237 120.836 347.526 120.836 349.117C120.836 350.707 122.125 351.997 123.716 351.997H128.962C130.553 351.997 131.842 350.707 131.842 349.117C131.842 347.526 130.553 346.237 128.962 346.237Z" fill="#E6E6E6"/>
<path d="M188.962 346.237H183.716C182.125 346.237 180.836 347.526 180.836 349.117C180.836 350.707 182.125 351.997 183.716 351.997H188.962C190.553 351.997 191.842 350.707 191.842 349.117C191.842 347.526 190.553 346.237 188.962 346.237Z" fill="#E6E6E6"/>
<path d="M205.962 346.237H200.716C199.125 346.237 197.836 347.526 197.836 349.117C197.836 350.707 199.125 351.997 200.716 351.997H205.962C207.553 351.997 208.842 350.707 208.842 349.117C208.842 347.526 207.553 346.237 205.962 346.237Z" fill="#E6E6E6"/>
<path d="M172.642 346.717H140.036C138.446 346.717 137.156 348.006 137.156 349.597C137.156 351.188 138.446 352.477 140.036 352.477H172.642C174.232 352.477 175.522 351.188 175.522 349.597C175.522 348.006 174.232 346.717 172.642 346.717Z" fill="#E6E6E6"/>
<path d="M144.962 333.237H139.716C138.125 333.237 136.836 334.526 136.836 336.117C136.836 337.707 138.125 338.997 139.716 338.997H144.962C146.553 338.997 147.842 337.707 147.842 336.117C147.842 334.526 146.553 333.237 144.962 333.237Z" fill="#E6E6E6"/>
<path d="M204.962 333.237H199.716C198.125 333.237 196.836 334.526 196.836 336.117C196.836 337.707 198.125 338.997 199.716 338.997H204.962C206.553 338.997 207.842 337.707 207.842 336.117C207.842 334.526 206.553 333.237 204.962 333.237Z" fill="#E6E6E6"/>
<path d="M221.962 333.237H216.716C215.125 333.237 213.836 334.526 213.836 336.117C213.836 337.707 215.125 338.997 216.716 338.997H221.962C223.553 338.997 224.842 337.707 224.842 336.117C224.842 334.526 223.553 333.237 221.962 333.237Z" fill="#E6E6E6"/>
<path d="M188.642 333.717H156.036C154.446 333.717 153.156 335.006 153.156 336.597C153.156 338.188 154.446 339.477 156.036 339.477H188.642C190.232 339.477 191.522 338.188 191.522 336.597C191.522 335.006 190.232 333.717 188.642 333.717Z" fill="#E6E6E6"/>
<path d="M160.962 320.237H155.716C154.125 320.237 152.836 321.526 152.836 323.117C152.836 324.707 154.125 325.997 155.716 325.997H160.962C162.553 325.997 163.842 324.707 163.842 323.117C163.842 321.526 162.553 320.237 160.962 320.237Z" fill="#E6E6E6"/>
<path d="M220.962 320.237H215.716C214.125 320.237 212.836 321.526 212.836 323.117C212.836 324.707 214.125 325.997 215.716 325.997H220.962C222.553 325.997 223.842 324.707 223.842 323.117C223.842 321.526 222.553 320.237 220.962 320.237Z" fill="#E6E6E6"/>
<path d="M237.962 320.237H232.716C231.125 320.237 229.836 321.526 229.836 323.117C229.836 324.707 231.125 325.997 232.716 325.997H237.962C239.553 325.997 240.842 324.707 240.842 323.117C240.842 321.526 239.553 320.237 237.962 320.237Z" fill="#E6E6E6"/>
<path d="M204.642 320.717H172.036C170.446 320.717 169.156 322.006 169.156 323.597C169.156 325.188 170.446 326.477 172.036 326.477H204.642C206.232 326.477 207.522 325.188 207.522 323.597C207.522 322.006 206.232 320.717 204.642 320.717Z" fill="#E6E6E6"/>
<path d="M94.1 547.04C94.71 547.35 95.33 547.66 95.94 547.96C111.74 555.86 124.38 560.35 141.67 565.27C143.13 548.97 144.54 520.32 145.91 502.56C146.27 497.95 146.63 494.08 146.98 491.35C147.068 489.899 147.365 488.467 147.86 487.1L147.8 491.35L147.65 502.56L146.78 566.67C168.287 572.361 190.381 575.545 212.62 576.16C213.78 576.19 214.92 576.21 216.02 576.23C216.41 576.24 216.79 576.25 217.17 576.25C218.42 576.26 219.66 576.27 220.91 576.27C244.346 576.287 267.697 573.457 290.45 567.84C289.96 551.71 289.47 523.38 289 502.56C288.9 498.49 288.81 494.7 288.71 491.35C288.52 484.19 288.32 479.03 288.13 477.3C288.298 477.607 288.429 477.932 288.52 478.27C289.446 482.582 290.078 486.952 290.41 491.35C290.76 494.7 291.11 498.49 291.46 502.56C293.32 524.19 295.26 553.6 297.26 566.07C309.164 562.82 320.846 558.802 332.23 554.04C338.23 551.54 344.123 548.843 349.91 545.95C348.81 538.75 347.66 530.68 346.48 522.31C345.56 515.82 344.61 509.15 343.64 502.56C342.14 492.38 319.85 421.24 318.44 418.54C317.23 416.23 316.08 414.05 314.99 412.04C312.35 407.16 310.11 403.27 308.45 400.81C301.51 390.283 291.178 382.446 279.17 378.6L278.87 378.52L278.66 378.61L258.21 372.33L255.22 371.41L253.21 370.79L246.13 365.4L242.28 362.47L238.67 359.72L231.61 354.35L187.9 357.04L179.39 377.53L160.33 384.5L160 384.35L159.71 384.23L159.41 384.31C147.404 388.159 137.073 395.996 130.13 406.52C129.19 407.93 128.05 409.79 126.76 412.04C125.66 413.95 124.46 416.13 123.16 418.54C121.31 421.97 119.29 425.84 117.15 430.03C116.68 430.95 116.2 431.89 115.72 432.84C114.83 434.6 113.927 436.397 113.01 438.23C110.88 442.48 108.68 446.92 106.48 451.43C104.57 455.34 102.64 463.38 100.74 473.61C99.1701 482.11 97.6101 492.13 96.1001 502.56C94.1201 516.08 96.2301 532.27 94.4401 544.71C94.33 545.5 94.21 546.27 94.1 547.04Z" fill="#3F3D56"/>
<path opacity="0.2" d="M303.59 473.566L295.093 440.774L287.59 469.07L303.59 473.566Z" fill="black"/>
<path d="M77.48 452.2C80.52 494.08 77.37 512.52 94.15 547.05C109.417 554.523 125.32 560.62 141.67 565.27C143.37 565.75 145.073 566.217 146.78 566.67C168.287 572.361 190.381 575.545 212.62 576.16C213.78 576.19 214.92 576.21 216.02 576.23C216.41 576.24 216.79 576.25 217.17 576.25C218.42 576.26 219.66 576.27 220.91 576.27C244.346 576.287 267.697 573.457 290.45 567.84C292.73 567.28 295 566.69 297.26 566.07C309.164 562.82 320.846 558.802 332.23 554.04C338.23 551.54 344.123 548.843 349.91 545.95C351.22 545.29 352.53 544.63 353.82 543.95C360.45 532.29 364.22 518.11 364.3 501.07C359.71 471.71 87.62 426.88 77.48 452.2Z" fill="#2F2E41"/>
<path opacity="0.2" d="M77.4879 459.372C77.3326 456.779 77.159 454.101 76.9565 451.312C87.0965 425.992 359.187 470.822 363.777 500.182C363.764 502.874 363.649 505.486 363.455 508.037C352.34 479.059 93.8505 436.451 77.4879 459.372Z" fill="black"/>
<path d="M15.8293 473.186C16.2699 474.469 17.1056 475.579 18.2165 476.357C19.3274 477.135 20.6563 477.541 22.0124 477.517L48.5042 476.835C49.8703 476.808 51.191 476.34 52.269 475.501C53.3471 474.662 54.1245 473.496 54.4851 472.178L58.234 432.091C59.564 432.693 61.012 432.991 62.4719 432.962C65.0323 432.896 67.4617 431.816 69.2261 429.959C70.9905 428.103 71.9456 425.621 71.8813 423.061C71.817 420.501 70.7387 418.07 68.8833 416.305C67.028 414.539 64.5474 413.582 61.987 413.645C61.4035 413.643 60.8214 413.704 60.2508 413.826C59.6302 413.154 58.8759 412.62 58.0364 412.258C57.197 411.896 56.291 411.713 55.3767 411.723L14.6262 412.775C14.2882 412.787 13.9519 412.829 13.621 412.9C12.7274 413.066 11.8798 413.422 11.1353 413.944C10.3908 414.466 9.76666 415.141 9.30493 415.924C8.8432 416.707 8.55459 417.58 8.45849 418.484C8.3624 419.388 8.46106 420.302 8.74785 421.164L15.8293 473.186ZM59.0028 429.369L61.6704 419.816C61.9738 418.713 61.9809 417.549 61.6908 416.442C61.8202 416.441 61.9344 416.38 62.0638 416.38C63.8929 416.342 65.6624 417.031 66.9843 418.296C68.3061 419.56 69.0725 421.298 69.1153 423.127C69.1581 424.956 68.4739 426.727 67.2127 428.053C65.9515 429.378 64.2163 430.149 62.3874 430.197C61.2049 430.234 60.0347 429.948 59.0028 429.369V429.369Z" fill="#2F2E41"/>
<path d="M13.6211 412.9C14.4892 417.864 24.2501 421.353 35.9869 420.747C46.7796 420.25 55.6044 416.46 57.2211 411.968C56.6228 411.791 56.0006 411.708 55.3768 411.723L14.6262 412.775C14.2882 412.787 13.9519 412.829 13.6211 412.9V412.9Z" fill="#3F3D56"/>
<path d="M38.138 451.158C38.1916 451.144 38.2426 451.122 38.2889 451.091L44.042 447.325C44.1116 447.28 44.1687 447.218 44.2083 447.145C44.2478 447.072 44.2686 446.99 44.2686 446.907C44.2687 446.824 44.248 446.743 44.2085 446.67C44.1689 446.597 44.1119 446.535 44.0423 446.489L38.0625 442.573C38.0077 442.536 37.9462 442.511 37.8815 442.499C37.8169 442.486 37.7505 442.487 37.6861 442.5C37.6216 442.513 37.5604 442.539 37.506 442.576C37.4515 442.613 37.4049 442.66 37.3689 442.715C37.3328 442.77 37.3079 442.832 37.2957 442.897C37.2835 442.961 37.2842 443.028 37.2978 443.092C37.3113 443.157 37.3374 443.218 37.3747 443.272C37.4119 443.326 37.4594 443.373 37.5146 443.408L42.8555 446.907L37.7416 450.255C37.6425 450.319 37.5697 450.417 37.536 450.53C37.5022 450.644 37.5098 450.765 37.5574 450.874C37.6049 450.982 37.6894 451.07 37.7957 451.122C37.902 451.174 38.0232 451.187 38.1379 451.158H38.138Z" fill="#0E6BA8"/>
<path d="M29.0512 451.577C29.1477 451.552 29.2348 451.499 29.3012 451.425C29.3676 451.351 29.4103 451.259 29.4238 451.16C29.4374 451.061 29.4211 450.961 29.3772 450.871C29.3333 450.782 29.2636 450.708 29.1772 450.658L23.8742 447.618L28.9998 443.811C29.0525 443.771 29.0969 443.722 29.1306 443.666C29.1643 443.61 29.1865 443.547 29.1961 443.482C29.2057 443.417 29.2024 443.351 29.1864 443.288C29.1704 443.224 29.142 443.164 29.1028 443.112C29.0637 443.059 29.0146 443.014 28.9583 442.981C28.902 442.947 28.8396 442.925 28.7746 442.915C28.7097 442.906 28.6436 442.909 28.5799 442.925C28.5163 442.941 28.4565 442.969 28.4038 443.009L22.6652 447.271C22.5985 447.321 22.5452 447.386 22.51 447.461C22.4749 447.536 22.4591 447.619 22.464 447.702C22.4688 447.785 22.4943 447.865 22.538 447.936C22.5817 448.006 22.6423 448.065 22.7143 448.106L28.6796 451.526C28.7922 451.59 28.9254 451.608 29.0512 451.577Z" fill="#0E6BA8"/>
<path d="M37.5657 454.458C37.6141 454.445 37.6603 454.426 37.7027 454.4C37.7588 454.366 37.8077 454.321 37.8465 454.268C37.8854 454.215 37.9133 454.155 37.9289 454.091C37.9445 454.027 37.9473 453.961 37.9372 453.896C37.9271 453.831 37.9044 453.769 37.8702 453.713L29.7147 440.304C29.6454 440.192 29.5342 440.111 29.4056 440.08C29.277 440.049 29.1414 440.07 29.0284 440.139C28.9154 440.208 28.8343 440.319 28.8028 440.447C28.7713 440.576 28.792 440.712 28.8603 440.825L37.0158 454.233C37.0715 454.324 37.1549 454.395 37.2538 454.436C37.3528 454.476 37.4621 454.484 37.5657 454.458V454.458Z" fill="#0E6BA8"/>
<path d="M671.009 398.186H582.009V476.186H671.009V398.186Z" fill="#3F3D56"/>
<path d="M684.2 289.236C682.55 299.014 683.999 309.064 688.345 317.978C689.953 321.257 691.945 325.207 689.986 328.289C689.437 329.059 688.722 329.697 687.894 330.156C687.067 330.615 686.147 330.883 685.202 330.94C683.312 331.04 681.42 330.795 679.619 330.217" fill="#CCCCCC"/>
<path d="M606.009 259.186C607.665 259.186 609.009 258.066 609.009 256.686C609.009 255.305 607.665 254.186 606.009 254.186C604.352 254.186 603.009 255.305 603.009 256.686C603.009 258.066 604.352 259.186 606.009 259.186Z" fill="#3F3D56"/>
<path d="M617.009 260.186C618.665 260.186 620.009 259.066 620.009 257.686C620.009 256.305 618.665 255.186 617.009 255.186C615.352 255.186 614.009 256.305 614.009 257.686C614.009 259.066 615.352 260.186 617.009 260.186Z" fill="#3F3D56"/>
<path d="M628.009 265.186C629.665 265.186 631.009 264.066 631.009 262.686C631.009 261.305 629.665 260.186 628.009 260.186C626.352 260.186 625.009 261.305 625.009 262.686C625.009 264.066 626.352 265.186 628.009 265.186Z" fill="#3F3D56"/>
<path d="M641.009 262.186C642.665 262.186 644.009 261.066 644.009 259.686C644.009 258.305 642.665 257.186 641.009 257.186C639.352 257.186 638.009 258.305 638.009 259.686C638.009 261.066 639.352 262.186 641.009 262.186Z" fill="#3F3D56"/>
<path d="M367.934 309.569C382.504 305.524 398.733 309.859 409.992 319.788C416.067 325.183 420.526 332.158 422.871 339.937C425.746 349.195 426.221 358.962 426.522 368.586C426.855 379.228 427.144 389.871 427.454 400.513C427.75 410.661 427.785 420.855 428.462 430.986C429.03 439.486 430.39 448.311 434.94 455.684C439.157 462.519 445.821 467.134 453.351 469.678C460.984 472.257 469.212 472.857 477.219 472.804C486.537 472.775 495.84 472.038 505.046 470.601C522.534 467.8 539.806 460.654 550.487 445.912C555.404 439.218 558.167 431.186 558.409 422.884C558.634 413.209 555.543 403.839 552.158 394.902C548.863 386.206 545.299 377.639 543.213 368.547C541.276 360.038 540.354 351.33 540.464 342.604C540.643 325.147 544.827 307.964 552.693 292.379C556.675 284.375 561.726 276.948 567.707 270.304C573.875 263.592 581.514 258.051 590.597 256.235C595.029 255.317 599.62 255.501 603.965 256.772C605.818 257.327 606.61 254.433 604.762 253.88C595.764 251.185 585.989 253.234 578.011 257.91C570.166 262.508 563.967 269.406 558.707 276.72C548.598 291.059 541.869 307.501 539.024 324.812C536.178 342.124 537.291 359.855 542.278 376.675C547.616 394.164 559.228 412.369 554.24 431.296C549.873 447.863 534.748 459.121 519.123 464.207C509.643 467.292 499.705 468.598 489.792 469.273C481.414 469.843 472.923 470.206 464.569 469.13C456.896 468.141 449.004 465.795 443.056 460.645C436.535 455 433.554 446.887 432.257 438.562C430.79 429.142 431.005 419.429 430.728 409.924L429.783 377.481C429.249 359.158 429.71 338.85 417.788 323.667C413.022 317.751 406.969 313 400.09 309.775C393.211 306.55 385.688 304.937 378.091 305.058C374.387 305.143 370.707 305.686 367.136 306.676C366.765 306.792 366.453 307.048 366.267 307.39C366.081 307.732 366.035 308.132 366.138 308.507C366.242 308.883 366.487 309.203 366.822 309.401C367.157 309.599 367.555 309.659 367.934 309.569H367.934Z" fill="#3F3D56"/>
<path d="M154.966 290.59C157.09 305.7 165.125 319.349 177.307 328.538C189.488 337.727 204.819 341.704 219.932 339.596C235.044 337.488 248.701 329.466 257.903 317.294C267.104 305.122 271.097 289.795 269.004 274.68L268.89 273.863C266.454 258.953 258.256 245.597 246.062 236.677C233.868 227.757 218.656 223.988 203.708 226.183C188.761 228.378 175.274 236.36 166.159 248.409C157.044 260.457 153.03 275.606 154.984 290.587L154.966 290.59Z" fill="#FFB6B6"/>
<path d="M205.718 338.194C202.742 332.198 199.683 325.761 200.492 319.116C201.3 312.471 207.793 306.101 214.195 308.053C218.024 309.221 220.928 312.988 224.926 313.168C230.428 313.415 233.561 307.109 235.256 301.868L242.158 280.526C255.472 290.985 238.611 280.02 255.102 276.191C261.572 274.689 268.104 271.781 271.884 266.32C275.664 260.858 275.612 252.406 270.389 248.303C267.828 246.292 264.426 245.535 261.978 243.388C260.459 242.056 259.409 240.27 258.984 238.295C258.559 236.32 258.781 234.26 259.618 232.421C260.454 230.582 261.861 229.061 263.629 228.084C265.397 227.106 267.432 226.723 269.434 226.993L251.868 224.857L257.068 215.377C253.308 218.361 248.063 218.779 243.336 217.944C238.609 217.109 234.154 215.179 229.528 213.897C207.321 207.744 181.572 218.658 170.55 238.895C166.225 237.467 161.567 237.387 157.194 238.665C152.822 239.943 148.941 242.52 146.066 246.054C141.386 252.036 140.134 260.001 139.977 267.595C139.686 282.568 143 297.391 149.64 310.813C151.709 314.985 154.156 319.077 157.673 322.128C161.191 325.18 200.945 342.083 205.533 341.286" fill="#2F2E41"/>
<path d="M621.009 298.686C621.016 301.281 620.684 303.867 620.019 306.376V306.385C620.009 306.435 619.989 306.486 619.979 306.536C617.959 313.966 613.139 319.186 607.509 319.186C601.909 319.186 597.109 314.005 595.059 306.635C594.919 306.125 594.799 305.616 594.689 305.086C594.549 304.496 594.439 303.886 594.349 303.256C594.269 302.756 594.209 302.246 594.159 301.726C594.089 301.006 594.039 300.286 594.029 299.536V299.526C594.009 299.246 594.009 298.966 594.009 298.686V298.436L594.019 298.426C594.02 297.158 594.103 295.892 594.269 294.635C594.339 294.105 594.419 293.596 594.519 293.086C594.639 292.466 594.769 291.856 594.929 291.256C595.059 290.726 595.209 290.216 595.369 289.706C595.589 289.026 595.829 288.366 596.099 287.736C596.269 287.326 596.449 286.926 596.629 286.536C599.089 281.476 603.049 278.186 607.509 278.186C613.679 278.186 618.879 284.476 620.489 293.056C620.509 293.186 620.539 293.315 620.549 293.445V293.456C620.857 295.182 621.011 296.932 621.009 298.686Z" fill="#E6E6E6"/>
<path d="M596.759 286.975C596.753 287.159 596.684 287.335 596.564 287.473C596.444 287.612 596.279 287.704 596.099 287.736C596.269 287.326 596.449 286.925 596.629 286.535C596.712 286.667 596.757 286.82 596.759 286.975Z" fill="#0E6BA8"/>
<path d="M608.581 286.065H599.782C599.352 286.065 599.004 286.413 599.004 286.842C599.004 287.271 599.352 287.619 599.782 287.619H608.581C609.011 287.619 609.359 287.271 609.359 286.842C609.359 286.413 609.011 286.065 608.581 286.065Z" fill="#0E6BA8"/>
<path d="M596.489 290.475C596.488 290.682 596.406 290.881 596.26 291.027C596.114 291.173 595.915 291.255 595.709 291.255H594.929C595.059 290.725 595.209 290.215 595.369 289.705H595.709C595.914 289.705 596.111 289.786 596.257 289.93C596.403 290.074 596.486 290.27 596.489 290.475V290.475Z" fill="#0E6BA8"/>
<path d="M600.301 289.703H598.885C598.456 289.703 598.108 290.051 598.108 290.48C598.108 290.909 598.456 291.257 598.885 291.257H600.301C600.731 291.257 601.079 290.909 601.079 290.48C601.079 290.051 600.731 289.703 600.301 289.703Z" fill="#0E6BA8"/>
<path d="M612.9 289.573H604.1C603.671 289.573 603.323 289.921 603.323 290.351C603.323 290.78 603.671 291.128 604.1 291.128H612.9C613.329 291.128 613.677 290.78 613.677 290.351C613.677 289.921 613.329 289.573 612.9 289.573Z" fill="#0E6BA8"/>
<path d="M600.031 293.211H598.616C598.186 293.211 597.838 293.559 597.838 293.989C597.838 294.418 598.186 294.766 598.616 294.766H600.031C600.461 294.766 600.809 294.418 600.809 293.989C600.809 293.559 600.461 293.211 600.031 293.211Z" fill="#0E6BA8"/>
<path d="M604.62 293.211H603.204C602.774 293.211 602.426 293.559 602.426 293.989C602.426 294.418 602.774 294.766 603.204 294.766H604.62C605.049 294.766 605.397 294.418 605.397 293.989C605.397 293.559 605.049 293.211 604.62 293.211Z" fill="#0E6BA8"/>
<path d="M596.409 293.856C596.408 294.062 596.326 294.261 596.18 294.407C596.034 294.553 595.835 294.635 595.629 294.636H594.269C594.339 294.106 594.419 293.596 594.519 293.086H595.629C595.73 293.085 595.831 293.104 595.926 293.142C596.02 293.18 596.106 293.237 596.179 293.308C596.251 293.38 596.309 293.465 596.348 293.559C596.388 293.653 596.408 293.754 596.409 293.856V293.856Z" fill="#0E6BA8"/>
<path d="M617.218 293.082H608.418C607.989 293.082 607.641 293.43 607.641 293.859C607.641 294.288 607.989 294.636 608.418 294.636H617.218C617.647 294.636 617.995 294.288 617.995 293.859C617.995 293.43 617.647 293.082 617.218 293.082Z" fill="#0E6BA8"/>
<path d="M596.759 298.975C596.758 299.077 596.738 299.178 596.698 299.272C596.659 299.366 596.601 299.451 596.529 299.523C596.456 299.594 596.37 299.651 596.276 299.689C596.181 299.727 596.08 299.746 595.979 299.745H594.569C594.368 299.746 594.176 299.671 594.029 299.535V299.525C594.009 299.245 594.009 298.965 594.009 298.685V298.435L594.019 298.425C594.09 298.353 594.176 298.295 594.27 298.255C594.365 298.216 594.466 298.195 594.569 298.195H595.979C596.185 298.196 596.384 298.278 596.53 298.424C596.676 298.57 596.758 298.769 596.759 298.975V298.975Z" fill="#CCCCCC"/>
<path d="M608.581 298.065H599.782C599.352 298.065 599.004 298.413 599.004 298.842C599.004 299.271 599.352 299.619 599.782 299.619H608.581C609.011 299.619 609.359 299.271 609.359 298.842C609.359 298.413 609.011 298.065 608.581 298.065Z" fill="#CCCCCC"/>
<path d="M596.489 302.476C596.488 302.682 596.406 302.881 596.26 303.027C596.114 303.173 595.915 303.255 595.709 303.256H594.349C594.269 302.756 594.209 302.246 594.159 301.726C594.203 301.709 594.251 301.702 594.299 301.705H595.708C595.914 301.705 596.111 301.786 596.257 301.93C596.403 302.074 596.486 302.27 596.489 302.476V302.476Z" fill="#CCCCCC"/>
<path d="M600.301 301.703H598.885C598.456 301.703 598.108 302.051 598.108 302.48C598.108 302.909 598.456 303.257 598.885 303.257H600.301C600.731 303.257 601.079 302.909 601.079 302.48C601.079 302.051 600.731 301.703 600.301 301.703Z" fill="#CCCCCC"/>
<path d="M612.9 301.573H604.1C603.671 301.573 603.323 301.921 603.323 302.351C603.323 302.78 603.671 303.128 604.1 303.128H612.9C613.329 303.128 613.677 302.78 613.677 302.351C613.677 301.921 613.329 301.573 612.9 301.573Z" fill="#CCCCCC"/>
<path d="M600.031 305.211H598.616C598.186 305.211 597.838 305.559 597.838 305.989C597.838 306.418 598.186 306.766 598.616 306.766H600.031C600.461 306.766 600.809 306.418 600.809 305.989C600.809 305.559 600.461 305.211 600.031 305.211Z" fill="#CCCCCC"/>
<path d="M604.62 305.211H603.204C602.774 305.211 602.426 305.559 602.426 305.989C602.426 306.418 602.774 306.766 603.204 306.766H604.62C605.049 306.766 605.397 306.418 605.397 305.989C605.397 305.559 605.049 305.211 604.62 305.211Z" fill="#CCCCCC"/>
<path d="M596.409 305.856C596.408 306.062 596.326 306.261 596.18 306.407C596.034 306.553 595.835 306.635 595.629 306.636H595.059C594.919 306.126 594.799 305.616 594.689 305.086H595.629C595.73 305.085 595.831 305.104 595.926 305.142C596.02 305.18 596.106 305.237 596.179 305.308C596.251 305.38 596.309 305.465 596.348 305.559C596.388 305.653 596.408 305.754 596.409 305.856Z" fill="#CCCCCC"/>
<path d="M617.218 305.082H608.418C607.989 305.082 607.641 305.43 607.641 305.859C607.641 306.288 607.989 306.636 608.418 306.636H617.218C617.647 306.636 617.995 306.288 617.995 305.859C617.995 305.43 617.647 305.082 617.218 305.082Z" fill="#CCCCCC"/>
</g>
<defs>
<clipPath id="clip0_4_107">
<rect width="738.82" height="576.27" fill="white"/>
</clipPath>
</defs>
</svg>

    );
  }
}
