/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Tuğberk Akbulut",
  description:
    "Etki yaratmak için sürdürülebilir ve ölçeklenebilir sistemler geliştiren, uçtan uca ürünler üzerinde çalışmak için her zaman tutkulu olan bir birey.",
  og: {
    title: "Tuğberk Akbulut",
    type: "website",
    url: "http://tuguberk.dev/",
  },
};

//Home Page
const greeting = {
  title: "Tuğberk Akbulut",
  logo_name: "Tugberk Akbulut",
  nickname: "tuguberk",
  subTitle:
    "Etki yaratmak için sürdürülebilir ve ölçeklenebilir sistemler geliştiren, uçtan uca ürünler üzerinde çalışmak için her zaman tutkulu olan bir birey.",
  //resumeLink:
  //  "https://drive.google.com/open?id=1XYpYhLeqCdyx_q6l0bQoC7RgwQjAjXPf",
  portfolio_repository: "https://github.com/ashutosh1919/masterPortfolio",
  githubProfile: "https://github.com/Tuguberk",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  {
    name: "Github",
    link: "https://github.com/Tuguberk",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/tuğberk-akbulut-43b7a71a0/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:akbulut.tugberk@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Twitter",
    link: "https://twitter.com/Tuguberk",
    fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
    backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/tugberk.akbulut/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Robotik & Elektronik",
      fileName: "DataScienceImg",
      skills: [
        "⚡ 3D yazıcılar, Arduino, Raspberry Pi, Nvidia Jetson Dev Kits gibi donanımlar için yazılım geliştiriyorum.",
        "⚡ Baskı devre (PCB) tasarımları yapıyorum.",
        "⚡ Görüntü işleme ve nesne tespiti üzerinde çalışıyorum.",
      ],
      softwareSkills: [
        {
          skillName: "Robotics",
          fontAwesomeClassname: "icon-park:robot-two",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Raspberry Pi",
          fontAwesomeClassname: "logos:raspberry-pi",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "3D",
          fontAwesomeClassname: "iconoir:3d-pt-box",
          style: {
            backgroundColor: "transparent",
            color: "#D00000",
          },
        },
        {
          skillName: "Communication",
          fontAwesomeClassname: "maki:communications-tower",
          style: {
            backgroundColor: "transparent",
            color: "#000000",
          },
        },
        {
          skillName: "OpenCv",
          fontAwesomeClassname: "logos:opencv",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos:tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        
      ],
    },
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Flutter ile mobil uygulamalar geliştiriyorum.",
        "⚡ Django ve Flask ile backend uygulamalar geliştiriyorum.",
        "⚡ React ile responsive web siteleri tasarlıyorum.",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "TailwindCSS",
          fontAwesomeClassname: "logos:tailwindcss-icon",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Bulut Sistemleri",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Web siteleri ve Api'ler için sunucuları yapılandırıyorum.",
        "⚡ Veritabanlarının entegrasyonunu gerçekleştiriyorum.",
      ],
      softwareSkills: [
        {
          skillName: "Server",
          fontAwesomeClassname: "fa-solid:server",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Nginx",
          fontAwesomeClassname: "logos:nginx",
          style: {
            color: "#1488C6",
          },
        },
        
      ],
    },
    {
      title: "Tasarım",
      fileName: "DesignImg",
      skills: [
        "⚡ Prototipleme aşamlarını görselleştirmek için tasarımlar yapıyorum.",
        "⚡ Projelerim için gerekli olan afiş ve logo tasarımlarını yapıyorum.",
        "⚡ 3D modelleme ile robotlarda ihtiyacım olan parçaları tasarlıyorum.",
      ],
      softwareSkills: [
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Inkscape",
          fontAwesomeClassname: "simple-icons:inkscape",
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "Fusion 360",
          fontAwesomeClassname: "simple-icons:autodesk",
          style: {
            color: "#2787BC",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    // {
    //   siteName: "HackerRank",
    //   iconifyClassname: "simple-icons:hackerrank",
    //   style: {
    //     color: "#2EC866",
    //   },
    //   profileLink: "https://www.hackerrank.com/layman_brother",
    // },
    // {
    //   siteName: "Codechef",
    //   iconifyClassname: "simple-icons:codechef",
    //   style: {
    //     color: "#5B4638",
    //   },
    //   profileLink: "https://www.codechef.com/users/ashutosh_1919",
    // },
    // {
    //   siteName: "Codeforces",
    //   iconifyClassname: "simple-icons:codeforces",
    //   style: {
    //     color: "#1F8ACB",
    //   },
    //   profileLink: "http://codeforces.com/profile/layman_brother",
    // },
    // {
    //   siteName: "Hackerearth",
    //   iconifyClassname: "simple-icons:hackerearth",
    //   style: {
    //     color: "#323754",
    //   },
    //   profileLink: "https://www.hackerearth.com/@ashutosh391",
    // },
    // {
    //   siteName: "Kaggle",
    //   iconifyClassname: "simple-icons:kaggle",
    //   style: {
    //     color: "#20BEFF",
    //   },
    //   profileLink: "https://www.kaggle.com/laymanbrother",
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Akçaabat Fen Lisesi",
      subtitle: "12. Sınıf Öğrencisiyim",
      logo_path: "akcaabat_fen.png",
      alt_name: "Akçaabat Fen Lisesi",
      duration: "2019 - Şuan",
      descriptions: [
        "⚡ Okulumda hala eğitim görmekteyim.",
      ],
      website_link: "https://akcaabatfenlisesi.meb.k12.tr",
    },
    {
      title: "Trabzon Naci Orhan Bilgisayar Bilimleri Ve Tasarım Becerileri Merkezi",
      subtitle: "",
      logo_path: "kodlayap.png",
      alt_name: "Trabzon Naci Orhan Bilgisayar Bilimleri Ve Tasarım Becerileri Merkezi",
      duration: "2018 - 2021",
      descriptions: [
        "⚡ Aldığım eğitimler sonucunda birçok proje geliştirdim ve yarışmalara katıldım.",
        "⚡ WRO, FLL, Teknofest Helikopter Tasarım Yarışması, Teknofest İnsanlık Yararına Teknoloji kategorilerinde ekip arkadaşlarım ile çalışmalar yaptık.",
      ],
      website_link: "https://kodlayaptrabzon.meb.gov.tr",
    },
    {
      title: "Deneyap Trabzon",
      subtitle: "",
      logo_path: "deneyap.png",
      alt_name: "Deneyap Trabzon",
      duration: "2019 - 2022",
      descriptions: [
        "⚡ Aldığımız eğitimler sonucunda ekip arkadaşlarımla birlikte birçok proje geliştirdik.",
        "⚡ Barbaros ROV olarak kurduğumuz insansız su altı takımı 2020-2021 sezonlarında Türkiye 4.lüğü aldı.",
      ],
      website_link: "https://www.deneyap.org",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "TÜBİTAK 52. Lise Öğrencileri Araştırma Projeleri Yarışması",
      subtitle: "TÜBİTAK 52. Lise Öğrencileri Araştırma Projeleri Yarışması Yazılım Alanı Türkiye 3.lüğü",
      logo_path: "turkiyeucunculuk.png",
      certificate_link:
        "",
      alt_name: "TÜBİTAK 52. Lise Ögrencileri Araştırma Projeleri Yarışması",
      color_code: "#8C151599",
    },
    {
      title: "TÜBİTAK 52. Lise Öğrencileri Araştırma Projeleri Yarışması",
      subtitle: "TÜBİTAK 52. Lise Öğrencileri Araştırma Projeleri Yarışması Yazılım Alanı Erzurum Bölge 1.liği",
      logo_path: "bolgebirinciligi.png",
      certificate_link:
        "",
      alt_name: "TÜBİTAK 52. Lise Ögrencileri Araştırma Projeleri Yarışması",
      color_code: "#8C151599",
    },
    {
      title: "PCAP: Programming Essentials in Python",
      subtitle: "The graduate of the PCAP: Programming Essentials in Python course, administered by the undersigned instructor, and provided by Cisco Networking Academy®in collaboration with OpenEDG Python Institute",
      logo_path: "pcap-python.png",
      certificate_link:
        "",
      alt_name: "PCAP: Programming Essentials in Python",
      color_code: "#8C151599",
    },
    {
      title: "Teknofest İnsansız Su Altı Sistemleri Yarışması Temel Kategori",
      subtitle: "TEKNOFEST İSTANBUL 2021 İnsansIz Su Altı Sistemleri Yarışması Temel Kategori Türkiye 4.sü",
      logo_path: "sualti2021.png",
      certificate_link:
        "barbarosrov.com",
      alt_name: "Teknofest İnsansız Su Altı Sistemleri Yarışması Temel Kategori",
      color_code: "#8C151599",
    },

    {
      title: "Teknofest İnsansız Su Altı Sistemleri Yarışması Temel Kategori",
      subtitle: "TEKNOFEST GAZİANTEP 2020 İnsansIz Su Altı Sistemleri Yarışması Temel Kategori Türkiye 4.sü",
      logo_path: "sualti2020.png",
      certificate_link:
        "barbarosrov.com",
      alt_name: "Teknofest İnsansız Su Altı Sistemleri Yarışması Temel Kategori",
      color_code: "#8C151599",
    },

    {
      title: "INTO ORBIT: Uzay Serüveni FIRST® LEGO® Leaque",
      subtitle: "23-24 Subat 2019 tarihlerinde ANFA Altinpark'ta gerceklestirilen, INTO ORBIT: Uzay Serüveni FIRST® LEGO® Leaque 15. Sezon istanbul Yerel Turnuvalari",
      logo_path: "fll.png",
      certificate_link:
        "",
      alt_name: "INTO ORBIT: Uzay Serüveni FIRST® LEGO® Leaque",
      color_code: "#8C151599",
    },
    {
      title: "81 İlde 81 Siber Kahraman Projesi",
      subtitle: "81 İlde 81 Siber Kahraman Projesi kapsamında Trabzon il Milli Eğitim Müdürlüğü desteği ile düzenlenen SiberKamp Programı",
      logo_path: "siber-kahraman.png",
      certificate_link:
        "",
      alt_name: "81 İlde 81 Siber Kahraman Projesi",
      color_code: "#8C151599",
    },
    {
      title: "Trabzon Robot Ligi Serbest Proje Yarışması 2019",
      subtitle: "Trabzon Robot Ligi 2019 Serbest Proje Yarışması İl Birincisi",
      logo_path: "serbestproje.png",
      certificate_link:
        "",
      alt_name: "Trabzon Robot Ligi Serbest Proje Yarışması 2019",
      color_code: "#8C151599",
    },
    {
      title: "Trabzon Robot Ligi 2018-2019",
      subtitle: "2018-2019 Eğitim-Öğretim yılında düzenlenen Trabzon Robot Ligi",
      logo_path: "trabzonrobotligi2019.png",
      certificate_link:
        "",
      alt_name: "Trabzon Robot Ligi 2018-2019",
      color_code: "#8C151599",
    },
    {
      title: "TÜBİTAK 4006 Bilim Fuarı 2019",
      subtitle: "Yol-İş Sendikası Ortaokulu TÜBİTAK 4006 Bilim Fuarı 2019",
      logo_path: "bilimfuari2019.png",
      certificate_link:
        "",
      alt_name: "TÜBİTAK 4006 Bilim Fuarı 2019",
      color_code: "#8C151599",
    },
    {
      title: "Vodafone Yarını Kodlayanlar",
      subtitle: "Türkiye Vodafone Vakfı ve Habitat Derneği ortaklığıyla uygulanan Yarını Kodlayanlar Projesi",
      logo_path: "yarınıkodlayanlar.png",
      certificate_link:
        "",
      alt_name: "Vodafone Yarını Kodlayanlar",
      color_code: "#8C151599",
    },
    {
      title: "RobotAfyon 2017",
      subtitle: "Ortaokullar Arası Ulusal Robot Yarışması 2017",
      logo_path: "robotafyon.png",
      certificate_link:
        "",
      alt_name: "RobotAfyon 2017",
      color_code: "#8C151599",
    },
    {
      title: "TÜBİTAK 4006 Bilim Fuarı 2017",
      subtitle: "Yol-İş Sendikası Ortaokulu TÜBİTAK 4006 Bilim Fuarı 2017",
      logo_path: "tübitak2017.png",
      certificate_link:
        "",
      alt_name: "TÜBİTAK 4006 Bilim Fuarı 2017",
      color_code: "#8C151599",
    },
  ],
};

// Experience Page
const experience = {
  title: "Deneyim",
  subtitle: "İş, Staj ve Gönüllülük",
  description:
    "Gönüllü olarak birçok takımda çalıştım. Öğrenci olduğum için şuanda iş ve staj tecrübem yok. Eğer bana staj veya burs sağlamak isterseniz lütfen benimle iletişime geçin.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Gönüllülük",
      experiences: [
        {
          title: "Barbaros Rov",
          company: "Deneyap Trabzon",
          company_url: "https://barbarosrov.com",
          logo_path: "barbaros.png",
          duration: "2021 - Şuan",
          location: "Trabzon",
          description:
            "Trabzon Deneyap Atölyesi'nin desteği ile kurduğumuz ve kaptanlığını yaptığım insansız su altı aracı takımı.",
          color: "#4285F4",
        },
        {
          title: "Trabzon Şehit Eren Bülbül Gençlik Merkezi",
          company: "Gençlik ve Spor Bakanlığı",
          company_url: "https://gsb.gov.tr/",
          logo_path: "gsb.png",
          duration: "2019 - Şuan",
          location: "Trabzon",
          description:
            "Gençlik merkezinde bulunan Deneyap Atölyeleri ile çeşitli etkinliklerde ve fuarlarda bulundum.",
          color: "#4285F4",
        },
        
      ],
    },
    {
      title: "Staj",
      experiences: [
      ],
    },
    {
      title: "İş",
      experiences: [
      ],
    },
    
    
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projelerim",
  description:
    "Projelerimde çok çeşitli teknolojileri kullanmaya ve öğrenmeye çalışıyorum. İzinler nedeniyle paylaşamadığım projelerim için üzgünüm :)",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "İletişim",
    profile_image_path: "profile.png",
    description:
      "Hemen hemen her sosyal medya platformunu kullanıyorum. Bana en hızlı Instagram üzerinden ulaşabilirsiniz.",
  },
  blogSection: {
    title: "Blog",
    subtitle:
      "Kendimi geliştirmek ve bildiklerimi paylaşmak için fırsat buldukça blog yazıyorum.",
    link: "https://dev.to/tuguberk",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Addres",
    subtitle:
      "Ambavadi vas, Kanodar, T.A.-Palanpur, Dist.-Banaskantha, Gujarat - 385520",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/MpMqtRZytFchMkZ76",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+91 8320758513",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  contactPageData,
};
